import React, { useEffect } from 'react';

// @ts-ignore
import alexMaverick from '../images/epic-moment/alex_maverick.jpg';
// @ts-ignore
import teamMatrix from '../images/epic-moment/team_matrix.jpg';
// @ts-ignore
import hannesSuperman from '../images/epic-moment/hannes_superman.jpg';
// @ts-ignore
import poldiPicard from '../images/epic-moment/poldi_picard.jpg';
import ImageAndText from './ImageAndText';
//audio
import audio1 from "../audios/ACDC.mp3";
import audio2 from "../audios/Applause.mp3";
import audio3 from "../audios/Happy.mp3";
import audio4 from "../audios/IFeelGood.mp3";

const EpicMoment = () => {
  const epicMomentsArray = [
    {
      img: alexMaverick,
      text:
        'Dank deiner tatkräftigen Unterstützung schaffen wir gemeinsam Großartiges!',
      author: 'Alex Deles',
      audioUrl: audio1
    },
    {
      img: hannesSuperman,
      text:
        'Vielen Dank für dein Engagement, wir sind stolz mit Menschen wie dir zusammenarbeiten zu dürfen!',
      author: 'Hannes',
      audioUrl: audio2
    },
    {
      img: poldiPicard,
      text:
        'Du bist großartig. Herzlichen Dank für deinen Einsatz und dein Engagement!',
      author: 'Poldi Machacek',
      audioUrl: audio3
    },
    {
      img: teamMatrix,
      text:
        'Wir freuen uns, dass du Teil unseres Teams bist. Menschen wie du machen den Unterschied!',
      author: 'Team',
      audioUrl: audio4
    },
  ];

  const [selectedEpicMoment, setSelectedEpicMoment] = React.useState(undefined);

  useEffect(() => {
    if (!selectedEpicMoment) {
      const randomIndex = Math.floor(Math.random() * epicMomentsArray.length);
      setSelectedEpicMoment(epicMomentsArray[randomIndex]);
    }
  }, []);

  return (
    <div className="epicMoment contentContainer">
      {selectedEpicMoment && (
        <ImageAndText selectedEpicMoment={selectedEpicMoment} />
      )}
    </div>
  );
};

export default EpicMoment;
