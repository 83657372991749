import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const ThankYouMsg = () => {
  trackCustomEvent({
    // string - required - The object that was interacted with (e.g.video)
    category: 'Epic moment',
    // string - required - Type of interaction (e.g. 'play')
    action: 'time out',
    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    label: 'The client wait 10 sec to see the epic moment',
  });

  return (
    <div className="thank-you contentContainer">
      <p className="title">Vielen Dank,</p>
      <p className="subtitle">
        dass du deinen EPIC Moment mit uns geteilt hast!
      </p>
      {/*<p className="text">Worum gehts bei diesen EPIC Moments?</p>*/}
      {/*<p className="text">Mehr Infos im EBCONT Confluence</p>*/}
      {/*<button onClick={()=> window.location.href='https://confluence.ebcont.com/display/EBCCM/EPICON+-+Charity+Marketing+Home'}>Zu Confluence</button>*/}
    </div>
  );
};

export default ThankYouMsg;
