import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import * as styles from './index.module.css';
import Background from '../components/Background';
import EpicMoment from '../components/EpicMoment';
import ThankYouMsg from '../components/ThankYouMsg';
import useSiteMetadata from '../hooks/use-site-meta-data';

export function Index() {
  const [thankYou, setThankYou] = useState(false);
  const { title, description } = useSiteMetadata();

  setTimeout(function () {
    setThankYou(true);
  }, 10000);

  return (
    <div>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {!thankYou && <Background componentToPaint={EpicMoment} />}
      {thankYou && <Background componentToPaint={ThankYouMsg} />}
    </div>
  );
}

export default Index;
