import React from 'react';

// @ts-ignore
import logoPath from '../images/epicon-logo-white.svg';
import { Link } from 'gatsby';

const Background = (props) => {
  return (
    <div className="background">
      <div className="img-row">
        <img src={logoPath} alt="Epicon logo" className="img-logo" />
      </div>
      <props.componentToPaint {...props} />
    </div>
  );
};

export default Background;
