import React from 'react';
import play_button from '../images/playButton.png';

const ImageAndText = (props) => {
  let audio = new Audio(props.selectedEpicMoment.audioUrl);

  const start = () => {
    document.getElementById("play_button").style.display = "none";
    audio.play();
  };

  return (
    <div className="image-and-text">
      <img
        src={props.selectedEpicMoment.img}
        alt={props.selectedEpicMoment.text}
        className="epic-image"
      />
      <p className="epic-text">"{props.selectedEpicMoment.text}"</p>
      <p className="epic-author">{props.selectedEpicMoment.author}</p>
      {/*<img*/}
      {/*  src={play_button}*/}
      {/*  id= "play_button"*/}
      {/*  alt="play to music"*/}
      {/*  style={{ width: '50px', height: '50px' }}*/}
      {/*  onClick={start}*/}
      {/*/>*/}
    </div>
  );
};

export default ImageAndText;
